import React, { useState, useEffect, useContext } from "react";
import {
  collection,
  getCountFromServer,
  getDocs,
  query,
  where,
  doc,
  orderBy,
  getDocFromServer,
} from "firebase/firestore";
import { auth, db } from "./../../firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import { Helmet } from "react-helmet";
import { Loader, Title, Text, Container } from "@mantine/core";
import styles from "./dashboard.module.css";
import { StatsGrid, StampsTable } from "../../components";
import { AppContext } from "../../App";

// user data
// {
//   "phone": "+977 9808201347",
//   "birthday": {
//       "seconds": 498940200,
//       "nanoseconds": 0
//   },
//   "name": "sharav",
//   "gender": "Male",
//   "fcmToken": "e1m6FQ0nQOmLJQhbhj37ed:APA91bFWe8a1HQNOX_KYy3vlxkS-NgFXkMKnXscSYewePgXmeqA3mRMBU8o-2owjWvNfzoIbWhLBiRUqxqwuTjytgf-KtrYkQaiBLNobZR3FidiEfnRmNhiayugun6SS93FiNdYa1sEj",
//   "image": ""
// }

// stamp data
// {
//   "claimed": false,
//   "stampedDate": {
//       "seconds": 1718421665,
//       "nanoseconds": 532000000
//   },
//   "stampedBy": "hUvYPhbVlINmacAXyaNmTXVHR3Y2",
//   "brandId": "RbFSquUg4KEXLIyUgFAY",
//   "stampCardId": "oKe4BFlc6s9itcMWejss",
//   "userId": "3KrWw7LcfAfsmfIH0dxQPts56XE3",
//   "outletId": "RJc9OMEiAmoetZhsc4v2",
//   "expiryDate": {
//       "seconds": 1718421780,
//       "nanoseconds": 0
//   }
// }

export const Dashboard = () => {
  const { globalState } = useContext(AppContext);

  const [counts, setCounts] = useState({
    brands: 0,
    stamps: 0,
    users: 0,
    rewards: 0,
    activeToday: 0,
    active24Hours: 0,
    newUsers: 0,
  });

  const [todayCounts, setTodayCounts] = useState({
    stamps: 0,
    rewards: 0,
  });

  const [latestStamps, setLatestStamps] = useState([]);
  const [allUsers, setBrandSpecificUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        window.location.href = "/admin";
      }
    });

    // Clean up the subscription
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countsData = {};
        const collections = ["brands", "stamps", "users", "rewards"];

        if (
          globalState.currentBrand !== "All" &&
          globalState.currentBrand !== undefined
        ) {
          console.log("fetching data for brand", globalState.currentBrand);

          const fetchCollectionCount = async (coll, fieldName, value) => {
            const q = query(
              collection(db, coll),
              where(fieldName, "==", value)
            );
            const querySnapshot = await getCountFromServer(q);
            return querySnapshot.data().count;
          };

          const fetchBrandUserCount = async (coll) => {
            const q = query(
              collection(db, coll, globalState.currentBrand, "clients")
            );
            const querySnapshot = await getCountFromServer(q);
            return querySnapshot.data().count;
          };

          await Promise.all(
            collections.map(async (coll) => {
              let count;
              if (coll === "users") {
                count = await fetchBrandUserCount("brands");
              } else {
                count = await fetchCollectionCount(
                  coll,
                  "brandId",
                  globalState.currentBrand
                );
              }
              countsData[coll] = count;
            })
          );
        } else {
          console.log("fetching data for all brands");
          await Promise.all(
            collections.map(async (coll) => {
              const querySnapshot = await getCountFromServer(
                collection(db, coll)
              );
              countsData[coll] = querySnapshot.data().count;
            })
          );

          await Promise.all([
            (async () => {
              const today = new Date();

              //active 24 hours
              const yesterday = new Date();
              yesterday.setDate(yesterday.getDate() - 1);
              const q24 = query(
                collection(db, "users"),
                where("lastOpened", ">=", yesterday),
                where("lastOpened", "<", today)
              );
              const querySnapshot24 = await getCountFromServer(q24);
              countsData.active24Hours = querySnapshot24.data().count;
              console.log("active 24 hours", countsData.active24Hours);

              // active today
              today.setHours(0, 0, 0, 0);
              const tomorrow = new Date(today);
              tomorrow.setDate(today.getDate() + 1);

              const q = query(
                collection(db, "users"),
                where("lastOpened", ">=", today),
                where("lastOpened", "<", tomorrow)
              );

              const querySnapshot = await getCountFromServer(q);
              countsData.activeToday = querySnapshot.data().count;
              console.log("active today", countsData.activeToday);

              // new users

              today.setDate(today.getDate());

              const qNewUsers = query(
                collection(db, "users"),
                where("createdAt", ">=", today),
              );
              const querySnapshotNewUsers = await getCountFromServer(qNewUsers);
              countsData.newUsers = querySnapshotNewUsers.data().count;
              console.log("new users", countsData.newUsers);

            })(),
          ]);
        }
        setCounts(countsData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [globalState.currentBrand]);

  useEffect(() => {
    setLoading(true);
    let todayCountsLocal = {};
    let yesterdayCounts = {};

    const fetchTodayData = async () => {
      try {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        const fetchCollectionCount = async (coll, fieldName) => {
          // const q = query(collection(db, coll), where(fieldName, '>=', today), where(fieldName, '<', tomorrow));
          var q;
          if (
            globalState.currentBrand !== "All" &&
            globalState.currentBrand !== undefined
          ) {
            q = query(
              collection(db, coll),
              where(fieldName, ">=", today),
              where(fieldName, "<", tomorrow),
              where("brandId", "==", globalState.currentBrand)
            );
          } else {
            q = query(
              collection(db, coll),
              where(fieldName, ">=", today),
              where(fieldName, "<", tomorrow)
            );
          }
          const querySnapshot = await getCountFromServer(q);
          return querySnapshot.data().count;
        };

        const [stampsCount, rewardsCount] = await Promise.all([
          fetchCollectionCount("stamps", "stampedDate"),
          fetchCollectionCount("rewards", "claimedDate"),
        ]);

        todayCountsLocal = {
          stamps: stampsCount,
          rewards: rewardsCount,
        };
        setTodayCounts(todayCountsLocal);
        setLoading(false);
        await fetchYesterdayData();
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    const fetchYesterdayData = async () => {
      setLoading(true);
      try {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        const fetchCollectionCount = async (coll, fieldName) => {
          // const q = query(collection(db, coll), where(fieldName, '>=', yesterday), where(fieldName, '<', today));
          var q;
          if (
            globalState.currentBrand !== "All" &&
            globalState.currentBrand !== undefined
          ) {
            q = query(
              collection(db, coll),
              where(fieldName, ">=", yesterday),
              where(fieldName, "<", today),
              where("brandId", "==", globalState.currentBrand)
            );
          } else {
            q = query(
              collection(db, coll),
              where(fieldName, ">=", yesterday),
              where(fieldName, "<", today)
            );
          }
          const querySnapshot = await getCountFromServer(q);
          return querySnapshot.data().count;
        };

        const [stampsCount, rewardsCount] = await Promise.all([
          fetchCollectionCount("stamps", "stampedDate"),
          fetchCollectionCount("rewards", "claimedDate"),
        ]);

        yesterdayCounts = {
          stamps: stampsCount,
          rewards: rewardsCount,
        };

        calculatePercentageDifference();
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    const calculatePercentageDifference = () => {
      const stampsDiff =
        yesterdayCounts.stamps !== 0
          ? ((todayCountsLocal.stamps - yesterdayCounts.stamps) /
              yesterdayCounts.stamps) *
            100
          : 0;
      const rewardsDiff =
        yesterdayCounts.rewards !== 0
          ? ((todayCountsLocal.rewards - yesterdayCounts.rewards) /
              yesterdayCounts.rewards) *
            100
          : 0;

      setTodayCounts((prevCounts) => ({
        ...prevCounts,
        stampsDiff: stampsDiff.toFixed(2),
        rewardsDiff: rewardsDiff.toFixed(2),
      }));
    };

    fetchTodayData();
  }, [globalState.currentBrand]);

  useEffect(() => {
    const fetchLatestData = async () => {
      setLoading(true);
      try {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        // Create base query
        let baseQuery = query(
          collection(db, "stamps"),
          orderBy("stampedDate", "desc"),
          where("stampedDate", ">=", today),
          where("stampedDate", "<", tomorrow)
        );

        // Add brand filter if specific brand is selected
        if (globalState.currentBrand && globalState.currentBrand !== "All") {
          baseQuery = query(
            collection(db, "stamps"),
            orderBy("stampedDate", "desc"),
            where("stampedDate", ">=", today),
            where("stampedDate", "<", tomorrow),
            where("brandId", "==", globalState.currentBrand)
          );
        }

        const querySnapshot = await getDocs(baseQuery);
        const stamps = querySnapshot.docs.map((doc) => doc.data());

        // Fetch users and brands data
        const userIds = Array.from(
          new Set(stamps.map((stamp) => stamp.userId))
        );
        const brandIds = Array.from(
          new Set(stamps.map((stamp) => stamp.brandId))
        );

        const [users, brands] = await Promise.all([
          Promise.all(
            userIds.map(async (userId) => {
              const userDoc = await getDocFromServer(doc(db, "users", userId));
              return { ...userDoc.data(), id: userId };
            })
          ),
          Promise.all(
            brandIds.map(async (brandId) => {
              const brandDoc = await getDocFromServer(
                doc(db, "brands", brandId)
              );
              return { ...brandDoc.data(), id: brandId };
            })
          ),
        ]);

        // Merge stamps data
        const stampsData = stamps.reduce((acc, stamp) => {
          const existingStamp = acc.find(
            (s) =>
              s.userId === stamp.userId &&
              s.stampedDate.seconds === stamp.stampedDate.seconds &&
              s.brandId === stamp.brandId
          );

          const user = users.find((user) => user.id === stamp.userId);
          const brand = brands.find((brand) => brand.id === stamp.brandId);

          if (existingStamp) {
            existingStamp.count++;
          } else {
            acc.push({
              ...stamp,
              count: 1,
              user,
              brand,
            });
          }
          return acc;
        }, []);

        setLatestStamps(stampsData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    // Only fetch data if globalState.currentBrand is defined
    if (globalState.currentBrand !== undefined) {
      fetchLatestData();
    }
  }, [globalState.currentBrand]);

  return (
    <>
      <Helmet>
        <title>Lollet Admin - Dashboard</title>
      </Helmet>
      <Container size="xxl" className={styles.container}>
        {error && (
          <Text color="red" align="center">
            {error}
          </Text>
        )}
        {loading ? (
          <Loader />
        ) : (
          <>
            <StatsGrid
              data={[
                globalState.currentBrand !== "All" &&
                globalState.currentBrand !== undefined
                  ? {
                      title: "Brand",
                      value: globalState.brands.find(
                        (brand) => brand.id === globalState.currentBrand
                      )?.name,
                      smallText: true,
                      diff: 0,
                      icon: "brands",
                    }
                  : {
                      title: "Brands",
                      value: counts.brands,
                      diff: 0,
                      icon: "brands",
                    },

                globalState.currentBrand !== "All" &&
                globalState.currentBrand !== undefined &&
                allUsers.length > 0
                  ? {
                      title: "Users",
                      value: allUsers.length,
                      diff: 0,
                      redirect: "/admin/users",
                      icon: "users",
                    }
                  : {
                      title: "Users",
                      value: counts.users,
                      diff: 0,
                      redirect: "/admin/users",
                      icon: "users",
                    },
                {
                  title: "Stamps",
                  value: counts.stamps,
                  today: todayCounts.stamps,
                  diff: todayCounts.stampsDiff,
                  icon: "stamps",
                },
                {
                  title: "Rewards",
                  value: counts.rewards,
                  today: todayCounts.rewards,
                  diff: todayCounts.rewardsDiff,
                  icon: "rewards",
                },
                {
                  title: "Active in 24h",
                  value: counts.active24Hours,
                  today: counts.activeToday,
                  diff: counts.newUsers,
                  diffText: "New Users Today",
                  icon: "users",
                },
              ]}
            />
            {/* {globalState.currentBrand === 'All' && (
              <Center>
                <Button variant="transparent" onClick={
                  () => {
                    window.location.href = '/admin/dashboard/brands';
                  }
                } align="center">View more Detail</Button>
              </Center>
            )} */}
            <Title order={2} align="center" mt="lg">
              Recent Stamps
            </Title>
            <StampsTable data={latestStamps} loading={loading} error={error} />
          </>
        )}
      </Container>
    </>
  );
};
