import { collection, doc, getDocFromServer, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { db } from '../../firebase-config';
import { Button, Container, Loader, Text } from '@mantine/core';
import { Helmet } from 'react-helmet';
import { UsersTable } from '../../components/usersTable/UsersTable';
import styles from './users.module.css';
import { AppContext } from '../../App';

export const Users = () => {
    const [latestUsers, setLatestUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    var lastVisible = useRef(null);
    const [currentPage, setCurrentPage] = useState(1);
    const fetchLimit = 10;
    const [hasMore, setHasMore] = useState(true);
    const [currentBrandUsers, setCurrentBrandUsers] = useState([]);

    const { globalState } = useContext(AppContext);

    useEffect(() => {
        // Reset pagination when brand changes
        lastVisible.current = null;
        setHasMore(true);
        setCurrentBrandUsers([]);
        setLatestUsers([]);
        setCurrentPage(1);
        fetchLatestData(fetchLimit, lastVisible);
    }, [globalState.currentBrand]);

    // Fetch users based on current brand filter
    const fetchUsers = async (fetchLimit, lastVisible) => {
        if (globalState.currentBrand && globalState.currentBrand !== 'All') {
            return await fetchUsersByBrand(globalState.currentBrand, fetchLimit, lastVisible);
        } else {
            return await fetchAllUsers(fetchLimit, lastVisible);
        }
    };

    // Fetch users that have stamps for a specific brand
    const fetchUsersByBrand = async (brandId, userFetchLimit, lastVisible) => {
        // Get the clients document from the brand
        let allUserIds;
        if (currentBrandUsers.length === 0) {
            const clientsQuery = query(collection(db, 'brands', brandId, 'clients'));
            const clientsSnapshot = await getDocs(clientsQuery);
            const clientsIds = clientsSnapshot.docs.map(doc => doc.id);
            if (clientsIds.length === 0) return { users: [], userIds: [], hasMore: false };
            console.log(clientsIds);
            // Get all userIds from the clients document
            allUserIds = clientsIds;
            setCurrentBrandUsers(allUserIds);
        }
        else {
            allUserIds = currentBrandUsers;
        }

        // Handle pagination
        const startIndex = lastVisible.current || 0;
        const endIndex = startIndex + userFetchLimit + 1;
        const paginatedUserIds = allUserIds.slice(startIndex, endIndex);

        const hasMoreResults = paginatedUserIds.length > userFetchLimit;
        const userIdsToFetch = hasMoreResults ? paginatedUserIds.slice(0, -1) : paginatedUserIds;

        if (userIdsToFetch.length === 0) return { users: [], userIds: [], hasMore: false };

        // Store the last index for pagination
        lastVisible.current = hasMoreResults ? startIndex + userFetchLimit : null;

        // Fetch user documents
        const usersQuery = query(
            collection(db, 'users'),
            where('__name__', 'in', userIdsToFetch)
        );
        const usersSnapshot = await getDocs(usersQuery);
        const users = usersSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));

        return {
            users,
            userIds: userIdsToFetch,
            hasMore: hasMoreResults
        };
    };

    // Fetch all users without brand filter
    const fetchAllUsers = async (fetchLimit, lastVisible) => {
        const usersQuery = !lastVisible.current
            ? query(collection(db, 'users'), orderBy('name'), limit(fetchLimit + 1))
            : query(collection(db, 'users'), orderBy('name'), startAfter(lastVisible.current), limit(fetchLimit + 1));

        const querySnapshot = await getDocs(usersQuery);
        const hasMoreResults = querySnapshot.docs.length > fetchLimit;
        const docsToUse = hasMoreResults ? querySnapshot.docs.slice(0, -1) : querySnapshot.docs;

        lastVisible.current = docsToUse[docsToUse.length - 1];
        const users = docsToUse.map(doc => ({ ...doc.data(), id: doc.id }));
        const userIds = users.map(user => user.id);

        return { users, userIds, hasMore: hasMoreResults };
    };

    // Fetch stamps for a specific user
    const fetchUserStamps = async (userId) => {
        const stampsQuery = query(collection(db, 'stamps'), where('userId', '==', userId));
        const stampsSnapshot = await getDocs(stampsQuery);
        return stampsSnapshot.docs.map(doc => doc.data());
    };

    // Fetch brand data for stamps
    const fetchBrandsForStamps = async (stamps) => {
        const brandIds = Array.from(new Set(stamps.map(stamp => stamp.brandId)));
        const brands = await Promise.all(brandIds.map(async brandId => {
            if (globalState.brands && globalState.brands[brandId]) {
                return { ...globalState.brands[brandId], id: brandId };
            }
            const brandDoc = await getDocFromServer(doc(db, 'brands', brandId));
            return { ...brandDoc.data(), id: brandId };
        }));
        return stamps.map(stamp => ({
            ...stamp,
            brand: brands.find(brand => brand.id === stamp.brandId)
        }));
    };

    // Fetch rewards for a specific user
    const fetchUserRewards = async (userId) => {
        const rewardsQuery = query(collection(db, 'rewards'), where('userId', '==', userId));
        const rewardsSnapshot = await getDocs(rewardsQuery);
        return rewardsSnapshot.docs.map(doc => doc.data());
    };

    // Main fetch function that coordinates all data fetching
    const fetchLatestData = async (userFetchLimit, lastVisible) => {
        setLoading(true);
        try {
            // Fetch users and their IDs
            const { users, userIds, hasMore, allStamps } = await fetchUsers(userFetchLimit, lastVisible);
            setHasMore(hasMore);

            // Fetch stamps and rewards for each user
            const userDataPromises = userIds.map(async userId => {
                // Filter stamps from already fetched stamps instead of making new query
                const userStamps = allStamps ?
                    allStamps.filter(stamp => stamp.userId === userId) :
                    await fetchUserStamps(userId);

                const stampsWithBrands = await fetchBrandsForStamps(userStamps);
                const rewards = await fetchUserRewards(userId);
                return { stamps: stampsWithBrands, rewards };
            });

            const userData = await Promise.all(userDataPromises);

            // Combine all data
            const enrichedUsers = users.map((user, index) => {
                const { stamps, rewards } = userData[index];
                return {
                    ...user,
                    stamps,
                    stampsCount: stamps.length,
                    brands: Array.from(new Set(stamps.map(stamp => stamp.brand))),
                    brandsCount: Array.from(new Set(stamps.map(stamp => stamp.brandId))).length,
                    rewards,
                    rewardsCount: rewards.length
                };
            });

            setLatestUsers(prev => [...prev, ...enrichedUsers]);
            setLoading(false);
        } catch (err) {
            setError(err.message);
            setLoading(false);
            setHasMore(false);
        }
    };

    return (
        <>
            <Helmet>
                <title>Lollet Admin - Users</title>
            </Helmet>
            <Container size="xxl" className={styles.container}>
                {error && <Text color="red" align="center">{error}</Text>}
                {loading ? (
                    <Loader />
                ) : (
                    <Container size="xxl" className={styles.container}>
                        <UsersTable data={latestUsers} loading={loading} error={error} />
                        {hasMore && (
                            <Container align='center' justify='center'>
                                <Button
                                    onClick={() => {
                                        setCurrentPage(currentPage + 1);
                                        fetchLatestData(fetchLimit, lastVisible);
                                    }}
                                >
                                    Show More
                                </Button>
                            </Container>
                        )}
                    </Container>
                )}
            </Container>
        </>
    )
}