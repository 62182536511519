import React, { useEffect, useState } from 'react';
import styles from './bizAppDownload.module.css';
import { Loader } from '@mantine/core';

const getDeviceInfo = () => {
    const userAgent = navigator.userAgent;
    const vendor = navigator.vendor;
    const isTouchable = 'ontouchend' in document;
    const isIPad = /\b(\w*Macintosh\w*)\b/.test(userAgent) && isTouchable;
    const isIPhone = /\b(\w*iPhone\w*)\b/.test(userAgent) && /\b(\w*Mobile\w*)\b/.test(userAgent) && isTouchable;
    const isMobile = isIPad || isIPhone || /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
    const isMacOS = !isMobile && !(isIPad || isIPhone) && /Mac OS/i.test(userAgent);

    const isIOS = isMobile && (isIPad || isIPhone) && /\b(\w*Apple\w*)\b/.test(vendor) && !/\b(\w*CriOS\w*)\b/.test(userAgent) && !/\b(\w*FxiOS\w*)\b/.test(userAgent);
    const isIOS_noVendor = isMobile && (isIPad || isIPhone);

    return {
        isTouchable,
        isIPad,
        isIPhone,
        isMobile,
        isMacOS,
        isIOS,
        isIOS_noVendor,
        vendor,
    };
};

export const BizAppDownload = () => {
    const [storeRedirectHappened, setStoreRedirectHappened] = useState(false);
    const [imagesLoaded, setImagesLoaded] = useState(false);

    // Add image sources in a constant for easier management
    const images = {
        logo: "https://firebasestorage.googleapis.com/v0/b/lollet-mis.firebasestorage.app/o/web_assets%2Flogo_biz.png?alt=media&token=8303b307-4489-453e-93a4-80f3b57fa32b",
        playStore: "https://firebasestorage.googleapis.com/v0/b/lollet-mis.firebasestorage.app/o/web_assets%2Fplay-store.png?alt=media&token=6e52a81c-c15f-4cb0-b873-f27309da9e58",
        appStore: "https://firebasestorage.googleapis.com/v0/b/lollet-mis.firebasestorage.app/o/web_assets%2Fapp-store.png?alt=media&token=68c12fd8-5585-4030-a419-091bf6ab90c9"
    };

    // Preload images and set imagesLoaded state
    useEffect(() => {
        let loadedImages = 0;
        const totalImages = Object.keys(images).length;

        const preloadImage = (src) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
                img.onerror = reject;
            });
        };

        Promise.all(Object.values(images).map(preloadImage))
            .then(() => setImagesLoaded(true))
            .catch(error => console.error('Error loading images:', error));
    }, []);

    // Handle store redirects after images are loaded
    useEffect(() => {
        if (!imagesLoaded) return;

        const deviceInfo = getDeviceInfo();
        if (deviceInfo.isMobile) {
            setStoreRedirectHappened(false);
            if (deviceInfo.isIPhone || deviceInfo.isIPad || deviceInfo.isIOS || deviceInfo.isIOS_noVendor) {
                setStoreRedirectHappened(true);
                window.location.href = 'https://apps.apple.com/ng/app/lollet-for-business/id6482977184';
            } else {
                setStoreRedirectHappened(true);
                window.location.href = 'https://play.google.com/store/apps/details?id=app.lollet.biz';
            }
        }
    }, [imagesLoaded]);

    useEffect(() => {
        // let redirectTimer;
        // if (storeRedirectHappened) {
        //     // Wait before redirecting to lollet.app
        //     redirectTimer = setTimeout(() => {
        //         window.location.href = 'https://lollet.app';
        //         setStoreRedirectHappened(false);
        //     }, 800);
        // }

        // // Cleanup timer if component unmounts
        // return () => {
        //     if (redirectTimer) {
        //         clearTimeout(redirectTimer);
        //     }
        // };
    }, [storeRedirectHappened]);

    return (
        <>
            {!imagesLoaded && (
                <div className={styles.loader}>
                    <Loader />
                </div>
            )}
            <div className={styles.container} style={{ opacity: imagesLoaded ? 1 : 0 }}>
                <img src={images.logo} alt="App Logo" className={styles.logo} />
                <div className={styles['download-buttons']}>
                    <a href="https://play.google.com/store/apps/details?id=app.lollet.biz" target="_blank" onClick={() => setStoreRedirectHappened(true)} rel="noreferrer">
                        <img src={images.playStore} alt="Download on Play Store" />
                    </a>
                    <a href="https://apps.apple.com/ng/app/lollet-for-business/id6482977184" target="_blank" onClick={() => setStoreRedirectHappened(true)} rel="noreferrer">
                        <img src={images.appStore} alt="Download on App Store" />
                    </a>
                </div>
            </div>
        </>
    );
}
