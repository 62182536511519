import { Group, Paper, Text, ThemeIcon, SimpleGrid } from "@mantine/core";
import {
  IconArrowUpRight,
  IconArrowDownRight,
  IconBuildingStore,
  IconRubberStamp,
  IconUsers,
  IconGift,
} from "@tabler/icons-react";
import classes from "./StatsGrid.module.css";

const icons = {
  brands: IconBuildingStore,
  stamps: IconRubberStamp,
  users: IconUsers,
  rewards: IconGift,
};

export function StatsGrid({ data }) {
  const stats = data.map((stat) => {
    const Icon = icons[stat.icon];
    const DiffIcon = stat.diff >= 0 ? IconArrowUpRight : IconArrowDownRight;

    return (
      <Paper
        withBorder
        p="md"
        radius="md"
        key={stat.title}
        style={{ cursor: stat.redirect ? "pointer" : null }}
        onClick={() => {
          if (stat.redirect) {
            window.location.href = stat.redirect;
          }
        }}
      >
        <Group justify="apart" gap={1}>
          <Group justify="space-between" w={"100%"}>
            <Text
              c="dimmed"
              tt="uppercase"
              fw={700}
              fz={stat.today ? "sm" : "xl"}
              className={classes.label}
            >
              {stat.title}
            </Text>
            <Icon className={classes.icon} size="1.4rem" stroke={1.5} />
          </Group>

          <Text fw={700} fz={stat.today || stat.smallText ? "xl" : "50"}>
            {stat.value}
          </Text>
        </Group>
        <br />
        {stat.today && (
          <>
            <Group justify="apart">
              <div>
                <Text
                  c="dimmed"
                  tt="uppercase"
                  fw={700}
                  fz="xs"
                  className={classes.label}
                >
                  Today
                </Text>
                <Text fw={700} fz="xl">
                  {stat.today}
                </Text>
              </div>
              {stat.diffText && (
                <ThemeIcon
                  color="gray"
                  variant="light"
                  style={{
                    color:
                      stat.diff >= 0
                        ? "var(--mantine-color-teal-6)"
                        : "var(--mantine-color-red-6)",
                  }}
                  size={38}
                  radius="md"
                >
                  <DiffIcon size="1.8rem" stroke={1.5} />
                </ThemeIcon>
              )}
            </Group>
            <Text c="dimmed" fz="sm" mt="md">
              <Text
                component="span"
                c={stat.diff >= 0 ? "teal" : "red"}
                fw={700}
              >
                {stat.diff}
                {!stat.diffText ? "%" : ""}
              </Text>{" "}
              {stat.diffText
                ? stat.diffText
                : stat.diff >= 0
                ? "increase"
                : "decrease"}
              {!stat.diffText && " compared to yesterday"}
            </Text>
          </>
        )}
      </Paper>
    );
  });

  return (
    <div className={classes.root}>
      <SimpleGrid cols={{ base: 1, sm: 3 }}>{stats}</SimpleGrid>
    </div>
  );
}
